body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
#App {
  overflow-x: hidden;
}
.bgblur {
  backdrop-filter: blur(10px);
}
.noscroll {
  position: fixed;
}

.anchor {
  height: 0;
  position: relative;
  top: -80px; // Shift up due to the pos
}

#navbar {
  width: 100%;
}

.textShadow {
  text-shadow: -1px -1px 0 #000, 4px -3px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.textShadowLight {
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
    1px 1px 0 #000000;
}
.lightboxImg {
  position: fixed;
  // top: 50vh;
  // left: 50vw;
  // transform: translate(-50%, -50%);
  max-width: 90vw;
  max-height: 90vh;
}
p,
.prewrap {
  white-space: pre-wrap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Patua One", "Bebas Neue", cursive;
  //font-family: "Yeseva One", cursive;
  text-transform: none !important;
  text-align: center;
}
h1 {
  font-size: 6rem;
  letter-spacing: 0.15rem;
  text-decoration: underline;
}
h2 {
  letter-spacing: 0.1rem;
  font-size: 3.8rem;
}
h3 {
  letter-spacing: 0.05rem;

  font-size: 2.8rem;
}
h4 {
  font-size: 1.9rem;
}

.h6 {
  height: 24rem;
}

.h7 {
  height: 32rem;
}
.h8 {
  height: 40rem;
}

.emphathize {
  font-weight: bold;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.borderyellow {
  border: 2px solid yellow;
}

.borderred {
  border: 2px solid red;
}

.borderblue {
  border: 2px solid blue;
}
