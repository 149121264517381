#hero {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.backgroundVideo video,
.backgroundVideo iframe {
  width: 100% !important;
  height: 100vh !important;
  min-width: 100;
  min-height: 100%;
  object-fit: cover !important;
}

#thumbnailPreview {
  filter: blur(20px);
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
}
#coverText {
  letter-spacing: 0.5rem;
  position: absolute;
  max-width: 70vw;
  left: 20vw;
  bottom: 10vh;
}

.coverText {
  font-family: "Patua One", "Bebas Neue", cursive;
  font-size: min(11vh, 8.2vw);
  font-weight: 1;
  letter-spacing: 0.5rem;
  position: absolute;
  top: 6rem;
  left: 0;
}

.ctaContainer {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
}

.arrow {
  position: absolute;
  left: 50%;
  text-align: center;
  bottom: 0;
  margin-bottom: 5vh;
  width: 50px;
  height: 50px;
  transform: translateX(-50%);
  padding: 0.3rem;
  background-color: #ffffff44;
  border-radius: 100%;
}
#arrow {
  z-index: 1;
}

.logoContainer {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
}

.logoHero {
  height: 90px;
  padding: 5px;
}
