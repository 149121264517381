$bottleheight: 400px;
$containerheight: 300px;
$gridgad: 130px;

.waveBg {
  background: url(../media/img/wave.svg) bottom center;
  background-size: cover;
}

.wineHeaderText {
  font-family: "Patua One", "Bebas Neue", cursive;
}
.wineOverview {
  max-width: 1200px;
}
#wineList {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 4;
  row-gap: $gridgad;
}
%wineContainer {
  position: relative;
  width: 100vw;
  height: $containerheight;
  overflow-y: visible;
}
.dimmed {
  opacity: 0.4;
  transition: opacity 0.5s;
  backdrop-filter: blur(5px);
}

.notdimmed {
  opacity: 1;
  transition: opacity 0.5s;
  backdrop-filter: blur(5px);
}

.grow {
  transform: scale(1.1) 0.5s;
}
.ungrow {
  transform: scale(1) 0.5s;
}

.wineRightContainer {
  @extend %wineContainer;
  left: 10vw;
  justify-self: start;
}
.wineLeftContainer {
  @extend %wineContainer;
  direction: rtl;
  right: 10vw;
}

.winePicture {
  position: absolute;
  top: 0;
  bottom: 0;
  height: $bottleheight;
  margin: auto;
}

.wineContent {
  position: absolute;
  // height: $bottleheight;
  height: $containerheight - 10px;
  direction: ltr;
}

.testimonial::before,
.testimonial::after {
  position: absolute;
  font-size: 14rem;
  color: #90928f;
  opacity: 0.4;
  line-height: 1;
  z-index: 1;
  font-family: sans-serif;
}
.testimonial::before {
  content: "\201c";
  top: 0;
  left: -2rem;
  @media screen and (min-width: 30em) and (max-width: 60em) {
    left: -5rem;
  }
  height: 1rem;
}
.testimonial::after {
  content: "\201d";
  top: 7rem;
  right: -2rem;
  @media screen and (min-width: 30em) and (max-width: 60em) {
    right: -5rem;
  }
  height: 1rem;
}

.testimonialAuthor {
  width: 10em;
  margin-top: -3.5em;
  //margin-bottom: -1em;
}
.badgeText {
  z-index: 5;
  position: absolute;
  margin-top: -2em;
  right: 0.2em;
}
.badgeGrade {
}
